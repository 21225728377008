



































































































































































































@import '../../scss/mixins';
@import '../../scss/vars';

nav.mobile-nav {
  position: relative;
  width: 100%;
  // TODO: make this better,we should calculate the height based on the content.
  min-height: 740px;
  overflow: hidden;

  .slide {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    transition: left 400ms ease-in-out;

    &.next {
      left: 100%;
    }

    &.previous {
      left: -100%;
    }

    a {
      display: flex;
      align-content: center;
      font-family: $font-snowflake-sans;
      font-size: 1.325rem;
      line-height: 2.75rem;
      max-width: 600px;
      padding-bottom: 1rem;
    }

    .submenu {
      padding: calc(#{$column} / 4) $column;
      font-size: 1.12rem;

      &.left {
        padding-left: 0;
      }
    }

    .breadcrumbs {
      font-size: 1.37rem;
      padding-right: 0;
      padding-left: 0;

      a {
        justify-content: flex-start;
      }
    }

    .arrow {
      display: inline-block;
      cursor: pointer;
      margin-left: 0.25rem;

      &:before {
        @include arrow(0.6rem, 3px, -45deg);
      }

      &.prev {
        width: $column;

        &:before {
          transform: rotate(135deg);
          margin-left: calc(#{$column} / 4);
        }
      }
    }
  }
}
