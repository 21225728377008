














































































































































































































































































































































@import '../../scss/common';

.filter-section {
  width: 100%;
}

.filter-toggle {
  font-size: 1rem;
  padding: 1rem 3rem 1rem 2rem;
  outline: none;

  &::after {
    position: absolute;
    content: '';
    right: 1.8rem;
    top: 1.1rem;
    width: 0.5rem;
    height: 0.5rem;
    border-right: 0.15rem solid $black;
    border-bottom: 0.15rem solid $black;
    transform: rotate(45deg);
  }

  &.active::after {
    transform: rotate(-45deg);
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  .tab-section {
    height: 100%;
    width: 46%;
    padding-bottom: 1rem;
    position: relative;
  }

  .tab {
    position: relative;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    padding: 1rem 2.3rem 5px 0;

    font-size: 1rem;
    margin: 0 1rem 0 0;
    background: $white;
    color: $black;
    outline: none;
    border-bottom: 1px solid black;

    &::after {
      content: '';
      position: absolute;
      top: 35%;
      right: 1%;
      width: 1px;
      border: solid $black;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(45deg);
    }

    &.active {
      position: relative;
      border-bottom: none;

      &::after {
        content: '';
        position: absolute;
        top: 35%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
      }
    }

    &:focus {
      border: 2px solid $grey-darkest;
    }
  }

  .tab-body {
    position: relative;
    order: 5;
    top: 1.8rem;
    left: 0;
    height: 100%;
    max-height: 27vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: $white;
    color: $black;
    padding: 0 0 1rem 0;
    align-content: flex-start;
    overflow-y: auto;
    box-sizing: border-box;

    .select {
      text-transform: capitalize;
      padding: 0.5rem;
      box-sizing: border-box;
    }

    label {
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      max-width: 100%;
    }

    input[type='checkbox'] {
      margin-right: 0.5rem;
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;

      ~ .checkbox {
        margin: 0 0.5rem 0 0;
        display: inline-block;
        position: relative;
        width: 1rem;
        height: 1rem;
        border: 2px solid $black;
      }

      &:focus ~ .checkbox {
        outline: 2px solid $grey-darkest;
      }

      &:checked ~ .checkbox {
        background: $black;

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 75%;
          height: 45%;
          border-bottom: 3px solid $white;
          border-left: 3px solid $white;
          box-sizing: border-box;
          top: 22.5%;
          left: 12.5%;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.gt-2 {
    .tab-section {
      width: 32%;
    }

    label {
      line-height: 1.5rem;
    }

    input[type='checkbox'] ~ .checkbox {
      min-width: 1rem;
    }
  }

  .selection-container {
    margin-top: 0.5rem;

    .selections {
      display: inline-block;
    }
  }

  .selected-options {
    display: flex;
    align-items: center;
    color: rgba(33, 35, 34, 0.7);
    background: rgba(33, 35, 34, 0.1);
    padding: 0.55rem;
    border-radius: 7px;
    margin: 0.5rem 0.5rem 0.5rem 0;

    button {
      background: transparent;
      padding: 5px;
      color: rgba(33, 35, 34, 0.7);
      border: 1px solid rgba(33, 35, 34, 0.7);
      border-radius: 50%;
      margin-left: 0.75rem;
      font-size: 0.5rem;
      display: flex;
    }

    .filter-remove {
      height: 0.5rem;
      width: 0.5rem;
    }
  }
}

// For tablet and up, make expanded filters absolute
// so they float over content.
@media screen and (min-width: $tablet) {
  .filters .tab-body {
    position: absolute;
    z-index: $z-dropdown;
    top: 40px;
    min-height: 100px;
    max-height: none;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

@include tablet() {
  .filters {
    .tab-body {
      max-height: 25vh;
    }

    &.gt-2 {
      flex-direction: column;

      .tab-section {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
}

@include mobile() {
  .filter-section {
    margin: 1rem 0 0;
  }
  .filters {
    padding: 2rem 0;
    flex-direction: column;

    .tab-section {
      display: block;
      width: 100%;
      margin-bottom: 1rem;
    }

    .tab {
      background: none;
      width: 100%;
      color: $black;
      padding: 1rem 0 0 0;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        top: 40%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(45deg);
      }

      &.active:after {
        top: 40%;
        right: 1%;
        width: 1px;
        border: solid $black;
        border-width: 0 1px 1px 0;
        display: inline-block;
        padding: 6px;
        transform: rotate(-135deg);
      }
    }

    .tab-body {
      position: static;
      flex-direction: column;
      height: auto;
      padding-left: 0;
      padding-right: 0;

      .select {
        flex: 1 1 100%;
        width: 100%;
        padding-left: 0;
        max-width: none;
      }
    }

    &.gt-2 {
      .tab-section {
        width: 100%;
      }
    }
  }
}
